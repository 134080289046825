import React from 'react'
import * as myStyles from './styles.module.scss'
import { TestimonialCardProps } from './types'
import ClickableArea from '../../basics/clickable-area/component'

const TestimonialCard = ({
    userIconSrc,
    buttonLabel,
    buttonLink,
    job,
    name,
    quote,
}: TestimonialCardProps) => {
    const styles: any = myStyles
    return (
        <div className={styles.c_testimonial_card}>
            <div className={styles.user}>
                <img src={userIconSrc} alt='user' className={styles.icon} />
                <span className={styles.name}>{name}</span>
                <span className={styles.job}>{job}</span>
            </div>
            <div className={styles.quote_wrapper}>
                <h4 className={styles.quote}>{quote}</h4>
                {buttonLink && buttonLabel && (
                    <ClickableArea
                        variant='primary'
                        color='blue'
                        href={buttonLink}
                        className={styles.button}>
                        {buttonLabel}
                    </ClickableArea>
                )}
            </div>
        </div>
    )
}

export default TestimonialCard
