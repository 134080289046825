import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as rawStyles from './styles.module.scss'
import { FixedTitleProps } from './types'

const FixedTitle: React.FC<FixedTitleProps> = ({ title }) => {
    const styles: any = rawStyles
    const titleRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)

        if (titleRef.current) {
            // Configuración de la animación y el ScrollTrigger
            gsap.fromTo(
                titleRef.current,
                { opacity: 1, y: 0 }, // Estado inicial: visible
                {
                    opacity: 0, // Estado final: invisible
                    ease: 'none',
                    scrollTrigger: {
                        trigger: titleRef.current,
                        start: 'top 50%', // Inicia cuando el título está en el centro del viewport
                        end: '+=530', // El fade out sucede en los siguientes 300px de scroll
                        scrub: true, // El efecto sigue el scroll del usuario
                        pin: true, // Mantiene el título fijo en su posición mientras se hace scroll
                        pinSpacing: false, // Evita crear espacio adicional mientras el título está fijo
                    },
                },
            )
        }

        return () => {
            // Limpieza: elimina todas las instancias de ScrollTrigger al desmontar el componente
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
        }
    }, [])

    return (
        <div className={styles.c_fixed_title}>
            <div className={styles.title} ref={titleRef}>
                {title}
            </div>
        </div>
    )
}

export default FixedTitle
