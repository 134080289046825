import React, { useState } from 'react'
import * as myStyles from './styles.module.scss'
import { TestimonialsProps } from './types'
import TestimonialCard from '../../components/testimonial-card'
import { uniqueId } from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, EffectFade, HashNavigation } from 'swiper'
import 'swiper/css/bundle'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'
import 'swiper/css/hash-navigation'

SwiperCore.use([Autoplay])
SwiperCore.use([EffectFade])
SwiperCore.use([HashNavigation])

const Testimonials = ({ intro, testimonials }: TestimonialsProps) => {
    const styles: any = myStyles
    const [activeTestimonial, setActiveTestimonial] = useState(0)
    return (
        <div className={styles.m_testimonials}>
            <div className={styles.wrapper}>
                <div>
                    {intro && <p className={styles.intro}>{intro}</p>}
                    {testimonials.length > 1 && (
                        <div className={styles.links_wrapper}>
                            {testimonials.map(({ title }, index) => (
                                <a
                                    key={uniqueId()}
                                    href={`#testimonial${index}`}
                                    onClick={() => setActiveTestimonial(index)}
                                    className={styles.link}
                                    data-is-active={activeTestimonial === index}>
                                    {title}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
                {testimonials.length > 1 ? (
                    <Swiper
                        slidesPerView={1}
                        loop={true}
                        modules={[EffectFade, Autoplay, HashNavigation]}
                        className={styles.swiper}
                        effect={'fade'}
                        speed={1000}
                        hashNavigation={{
                            replaceState: true,
                            watchState: true,
                        }}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: true,
                        }}
                        onSwiper={(swiper) => {
                            if (swiper && swiper.autoplay) {
                                swiper.autoplay.start()
                            }
                        }}
                        onHashChange={(swiper) => {
                            if (swiper && swiper.autoplay) {
                                swiper.autoplay.stop()
                            }
                        }}
                        onSlideChange={(swiper) => {
                            if (swiper && swiper.autoplay && swiper.autoplay.running) {
                                const index = (swiper.activeIndex - 1) % testimonials.length
                                setActiveTestimonial(index)
                            }
                        }}>
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide
                                key={`#testimonial${index}`}
                                data-hash={`testimonial${index}`}>
                                {({ isActive }) => (
                                    <div className={styles.slide} data-is-active={isActive}>
                                        <TestimonialCard {...testimonial} />
                                    </div>
                                )}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <TestimonialCard {...testimonials[0]} />
                )}
            </div>
        </div>
    )
}

export default Testimonials
